import { Star } from '@mui/icons-material';
import * as React from 'react';
import './style.css';
export default function HotelName ({name, stars}){

    let starIcons = []

    for(let i=0;i<stars;i++){
        starIcons.push(<Star/>)           
    }

    return(
        <span className="flex-row" style={{fontSize:"1.3rem", justifyContent:"space-around"}}><p>{name}  </p>
        <div style={{textAlign:"left"}}> 
        {
            starIcons.map((star, index)=>{
                return <span key={index}>{star}</span>
            })
        }
        </div> 
        </span>
    )
}