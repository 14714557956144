import * as React from 'react';
import Form from '../formElements/Form';
import Modal from './Modal';
import { makeFormControls, validateForm } from '../formElements/util';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function TableModal({field, formData, tableDataSource, setTableDataSource, hidden, setHidden, modalMode, setModalMode, buttons=null}){
    const [formControls, setFormControls] = React.useState(makeFormControls(formData));
    const [rowToEditOrder, setRowToEditOrder] = React.useState(null);

    let form = <div className="flex-row" style={{flexWrap:"wrap", gap:"1.2rem"}}><Form formData={formData} formControls={formControls} setFormControls={setFormControls}/></div>

    const loadRow = (row) => {
        setRowToEditOrder(row.order);

        setFormControls((prevState)=>{
            for(let value in prevState){
            prevState[value].value = row[value];
            
        }
        return prevState;
        });
        
    }

    const clearModal = () => {
        setFormControls(makeFormControls(formData));
    }
    
    const deleteRow = (deletedRow) =>{
        setTableDataSource((prevState)=>{
            let newDataSource = prevState[field.name].value.slice()
            newDataSource.splice(deletedRow, 1)
            for(let [index, ds] of newDataSource.entries()){
                ds.order = index+1;
                ds = addActions(ds)
            }
            let newField = {};
            let validity = {valid:newDataSource.length>0, necessary:field.necessary, formNecessary:field.formNecessary!=null?field.formNecessary:true, warning:newDataSource.length>0?"":"Tablica ne smije biti prazna!"}
            newField[field.name] = {value: newDataSource, validity:validity};
            return {
                ...prevState,
                ...newField
            }
        });
    }
    
    const addActions = (row) => {
        row.actions = <div key={row.order} className="flex-row">
            <button onClick={()=>{
            deleteRow(row.order-1)
        }} className="icon-button"><DeleteIcon /></button>
        <button onClick={()=>{
            setModalMode("edit");
            loadRow(row);
            setHidden(false);
        }} className="icon-button"><EditIcon /></button></div>
        return row;
    }
    const extractRow = ()=>{
        let newRow = {}
        for(let value of formData){
            if(value.type!=='subtitle'){
            newRow[value.name] = formControls[value.name].value;
            }
        }
        if(formControls['order']!=undefined){
            newRow.order = formControls['order'];
        } else {
            newRow['order'] = tableDataSource.length+1;
        }
        return newRow;
    }

    const addRow = () => {
        setTableDataSource((prevState)=>{
            let row = extractRow();
            row['order'] = tableDataSource[field.name].value.length+1
            let newDataSource = prevState[field.name].value.slice()
            newDataSource.push(addActions(row))
            let newField = {}
            let validity = {valid:newDataSource.length>0, necessary:field.necessary, formNecessary:field.formNecessary!=null?field.formNecessary:true, warning:newDataSource.length>0?"":"Tablica ne smije biti prazna!"}
            newField[field.name] = {value: newDataSource, validity:validity}
            return{
                ...prevState,
                ...newField
            }
        })
    }
    
    const addRowButton = <button className="modal-button" 
    onClick={()=>{
        let formControlsValid = validateForm(formData,formControls, setFormControls);
        if(formControlsValid){
            setHidden(true);
            addRow();
            clearModal();
        }
    }}>Dodaj redak</button>


    const updateRow = (newRow) => {
        let newDataSource= tableDataSource[field.name].value.slice();
        newRow['order'] = rowToEditOrder;
        newDataSource.splice(rowToEditOrder-1,1, newRow);
        setTableDataSource((prevState)=>{
            prevState[field.name].value = newDataSource;
            return prevState
        });
        setRowToEditOrder(null);
    }


    const editRowButton = <button className="modal-button"
    onClick={()=>{
        let formControlsValid = validateForm(formData,formControls, setFormControls);
        if(formControlsValid){
            let newRow = extractRow();
            updateRow(addActions(newRow));
            setHidden(true);
            clearModal();
        }
    }}
    >Izmijeni redak</button>

    const cancelButton = <button className="modal-button-transp" 
    onClick={()=>{
        setHidden(true);
        clearModal();
    }}>Odustani</button>


    return (
        <Modal title={modalMode=="add"?"Novi redak":"Izmjena"} hidden={hidden} content={form} buttons={[modalMode=="add"?addRowButton:editRowButton, cancelButton]}/>
    )
}