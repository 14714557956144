import * as React from 'react';
import {Link, NavLink} from 'react-router-dom';

import '../../assets/style.css';
import './style.css';
import logo from "../../assets/logo/logo.svg";
import BasicDropdown from '../dropdown/BasicDropdown';

export default function Navbar(){

    const elements = [
        {
            text: 'Naslovna',
            link: '/',
            key: 'naslovna'
        },
        {
            text: 'Organizacija',
            link: '/organizacija',
            key: 'organizacija'
        },
        {
            text: 'Oblici sudjelovanja',
            link: '/oblici-sudjelovanja',
            key: 'oblici-sudjelovanja'
        },
        {
            text: 'Program',
            key: 'program',
            elements:[
                {
                    text: "Pozvani predavači",
                    link: "/pozvani-predavaci",
                    key: "pozvani-predavaci"
                },
                {
                    text: 'Tematska područja',
                    link: "/tematska-podrucja",
                    key: "tematska-podrucja"
                }
            ]
        },
        {
            text: 'Prijava sažetaka',
            link: '/prijava-sazetaka',
            key: 'prijava-sazetaka'
        },
        {
            text: 'Prijava sudjelovanja',
            link: '/prijava-sudjelovanja',
            key: 'prijava-sudjelovanja'
        },

    ]

    return (
        <div id="nav" className="nav-dark">
            <div style={{padding:"20px"}}>
            <div className="flex-row" style={{alignItems:"center", justifyContent:"space-between"}}><Link to="/"><img  src={logo} style={{width: "15vw", height: "4.6vh"}}/></Link>
            <div style={{paddingTop: "2vh", paddingBottom:"2vh"}}>
            <div id="nav-list"  className="flex-row">
                {elements.map(({text, link, key, elements}, index)=>{
                    return link!=null?
                    <NavLink key={key} to={link}> <div className={"nav-link " + (index%2==0?"nav-link-even":"nav-link-odd")}>{text}</div></NavLink>
                    :<BasicDropdown key={key}  elements={elements} activeElement={<div className={"nav-link " +(index%2==0?"nav-link-even":"nav-link-odd")}>{text}</div>}/>
                    
                    
                })}
            </div>
            </div>
            </div>
            </div>
        </div>
    )
}