import * as React from 'react';
import { FormDataBuilder, makeFormControls, validateForm } from '../formElements/util';
import { isEmail, isEmpty, isTable } from '../formElements/validators';
import Form from '../formElements/Form';
import Modal from '../modal/Modal';
import { api } from '../../environment';

export default function PresentationForm(){

    const [formData, setFormData] = React.useState(null);
    const [formControls, setFormControls] = React.useState(null);
    const [formTitle, setFormTitle] = React.useState("")
    const [modalHidden, setModalHidden] = React.useState(true);

    const header = [{name:"order", label:"#"}, 
    {name:"first_name", label:"Ime", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"}, 
    {name:"last_name", label:"Prezime", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"}, 
    {name:"institution", label:"Institucija", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"},
    {name:"title", label:"Titula", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}},
    {name:"city", label:"Grad", formField: {necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"},
    {name:"state", label:"Država", formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 45%'}}, overflowX:"scroll"},
    {name:"actions", label:"Akcija", position:"sticky"}];

    const symposiumHeader = [
        {name:"order", label:"#"},
        {name:'active_participant',
        formField:{necessary:true, header:header, type:"tabular", validators:[isTable], layoutStyle:{flex: '1 1 100%'}, cols:['order', 'first_name', 'last_name', 'title', 'actions']}, 
        show:(arr)=>{return <div>{arr.active_participant[0].last_name + ", " + arr.active_participant[0].first_name}</div>}, label:'Autori', tableLabel:'Voditelj', layoutStyle:{flex:"1 1 100%"}},
        {name:'presenter', formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}}, label:'Ime i prezime predavača', layoutStyle:{flex:'1 1 45%'}},    
        {name:'presenter_email', formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex:'1 1 45%'}, label:'e-mail adresa predavača'}, layoutStyle:{flex:'1, 1, 45%'}},
        {formField:{type:'subtitle', text:'Osoba za kontakt', layoutStyle:{flex:'1 1 100%'}}},
            {name:'contact', formField:{necessary:true, validators:[isEmpty], type:'text', layoutStyle:{flex:'1 1 45%'}}, label:'Ime i prezime', layoutStyle:{flex:'1 1 45%'}},
            {name:'contact_email', formField:{necessary:true, type:'text', validators:[isEmpty, isEmail], layoutStyle:{flex:'1 1 45%'}}, label:'e-mail adresa', layoutStyle:{flex:'1 1 45%'}},
            {formField:{type:'subtitle', text:'Podatci o izlaganju'}},
            {name:'activity_title',  formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 100%'}}, label:"Naslov", layoutStyle:{flex:'1 1 100%'}},
            {name:'abstract', formField:{necessary:true, type:"textarea", validators:[isEmpty], layoutStyle:{flex: '1 1 100%'}}, label:'Sažetak', layoutStyle:{flex:'1 1 100%'}},
            {name:'keywords', formField:{necessary:true, type:"text", validators:[isEmpty], layoutStyle:{flex: '1 1 100%'}}, label:'Ključne riječi', layoutStyle:{flex:'1 1 100%'}},
            {name:'note', formField:{necessary:false, type:"textarea", layoutStyle:{flex: '1 1 100%'}}, label:"Napomena", layoutStyle:{flex:"1 1 100%"}},
            {name:"actions", label:"Akcija", position:"sticky"}
    ]

    const thematicFields = [
        {value:"1", text:"Uloga i doprinos psihologije u zaštiti mentalnog zdravlja"},
        {value:"2", text:"Mentalno zdravlje i radna produktivnost u doba digitalne transformacije"},
        {value:"3", text:"Uloga i doprinos psihologa u sustavu odgoja i obrazovanja"},
        {value:"4", text:"Zaštitni i rizični čimbenici u prilagodbi na bolest"},
        {value:"5", text:"Izazovi za psihologe"},
        {value:"6", text:"Uloga i doprinos psihologije u interdisciplinarnoj suradnji"},
    ]

    const handleFormTypeChange = (e) =>{
        let newFormData = new FormDataBuilder();
        if(e.target.value!=="3"){
            newFormData = newFormData
            .addTabular("active_participant",  "Popis autora/aktivnih sudionika", true, header, [isTable],  {flex:"1 1 100%"}, ['order','first_name', 'last_name', 'title', 'actions'])
            .addTextField("presenter", "Ime i prezime predavača/voditelja aktivnosti", true, true, [isEmpty], {flex:"1 1 45%"})
            .addTextField("presenter_email", "e-mail adresa voditelja aktivnosti", true, true, [isEmpty, isEmail], {flex:"1 1 45%"})
            .addSubtitle("Osoba za kontakt")
            .addTextField("contact", "Ime i prezime", true, true, [isEmpty], {flex:"1 1 45%"})
            .addTextField("contact_email", "e-mail adresa", true, true, [isEmpty, isEmail], {flex:"1 1 45%"})
            
        } else {
            newFormData = newFormData
            .addSubtitle("Podatci o voditelju simpozija")
            .addTextField("first_name", "Ime", true, true, [isEmpty], {flex:"1 1 45%"}, null, "Podatci o voditelju simpozija")
            .addTextField("last_name", "Prezime", true, true, [isEmpty], {flex:"1 1 45%"})
            .addTextField("institution", "Institucija", true, true, [isEmpty], {flex:"1 1 45%"})
            .addTextField("title", "Titula", true, true, [isEmpty], {flex:"1 1 45%"})
            .addTextField("city", "Grad", true, true, [isEmpty], {flex:"1 1 45%"})
            .addTextField("state", "Država", true, true, [isEmpty], {flex:"1 1 45%"})
            .addTextField("email", 'e-mail adresa', true, true, [isEmpty, isEmail], {flex:"1 1 100%"})
            .addSubtitle("Osoba za kontakt")
            .addTextField("contact", "Ime i prezime", true, true, [isEmpty], {flex:'1 1 45%'})
            .addTextField("contact_email", 'e-mail adresa', true, true, [isEmpty, isEmail], {flex:"1 1 45%"})
        }
        newFormData = newFormData
        .addSubtitle("Podatci o aktivnosti")
        .addTextField("activity_title", "Naslov", true, true, [isEmpty], {flex:"1 1 100%"})
        .addSelectField("theme", "Tematsko područje", true, thematicFields, true, [isEmpty], {flex:"1 1 100%"})
        .addTextArea("abstract", "Sažetak", true, [isEmpty], {flex:"1 1 100%"})
        .addTextField("keywords", "Ključne riječi", true, true, [isEmpty], {flex:"1 1 100%"})

        if(e.target.value==="3"){
            newFormData = newFormData
            .addTabular("activity", "Popis usmenih izlaganja u okviru simpozija", true, symposiumHeader, [isTable], {flex:"1 1 100%"}, ['order', 'active_participant', 'activity_title', 'actions'])
        }
        
        if(e.target.value==="4"||e.target.value==="5"){
            newFormData=newFormData
            .addSelectField("timing", "Trajanje aktvnosti (u minutama)", true, [
                {value:"30", text:"30"},
                {value:"60", text:"60"},
                {value:"90", text:"90"}
            ], true, [isEmpty], {flex:"1 1 100%"})
        } else if(e.target.value==="6"){
            newFormData=newFormData
            .addSelectField("timing", "Trajanje aktvnosti (u minutama)", true, [
                {value:"5", text:"5"},
                {value:"10", text:"10"},
                {value:"15", text:"15"},
                {value:"20", text:"20"},
                {value:"25", text:"25"},
                {value:"30", text:"30"},
                {value:"35", text:"35"},
                {value:"40", text:"40"},
                {value:"45", text:"45"},
                {value:"50", text:"50"},
                {value:"55", text:"55"},
                {value:"60", text:"60"},
                {value:"65", text:"65"},
                {value:"70", text:"70"},
                {value:"75", text:"75"},
                {value:"80", text:"80"},
                {value:"85", text:"85"},
                {value:"90", text:"90"},
            ], true, [isEmpty], {flex:"1 1 100%"})
        } else if(e.target.value==="7"){
            newFormData=newFormData
            .addSelectField("timing", "Trajanje aktvnosti (u minutama)", true, [
                {value:"30", text:"30"},
                {value:"60", text:"60"}
            ], true, [isEmpty], {flex:"1 1 100%"})
        } else if(e.target.value==="8"){
            newFormData=newFormData
            .addSelectField("timing", "Trajanje aktvnosti (u minutama)", true, [
                {value:"15", text:"15"},
                {value:"30", text:"30"},
                {value:"60", text:"60"}
            ], true, [isEmpty], {flex:"1 1 100%"})
        } else if(e.target.value==="9"){
            newFormData=newFormData
            .addSelectField("timing", "Trajanje aktvnosti (u minutama)", true, [
                {value:"5", text:"5"},
                {value:"10", text:"10"},
                {value:"15", text:"15"}
            ], true, [isEmpty], {flex:"1 1 100%"})
        }

        newFormData=newFormData.addTextArea("note", "Napomena", false, [], {flex:"1 1 100%"})

        newFormData=newFormData.get()
        
        setFormData(newFormData);
        setFormControls(makeFormControls(newFormData))
        let index = e.nativeEvent.target.selectedIndex;
        setFormTitle(e.nativeEvent.target[index].text)
    }

    const typeSelectorData = [
        {name:"type", type:'select', label:'Odaberite oblik izlaganja', necessary: true, validators:[isEmpty],
    options:[
    {value:"1", text:"Usmeno izlaganje"},
    {value:"2", text:"Poster izlaganje"},
    {value:"3", text:"Simpozij"},
    {value:"4", text:"Okrugli stol"},
    {value:"5", text:"Radionica"},
    {value:"6", text:"Stručni domjenak"},
    {value:"7", text:"Sastanak stručne sekcije/radne skupine"},
    {value:"8", text:"Predstavljanje knjiga, psihologijskih mjernih instrumenata te drugih publikacija i uradaka"},
    {value:"9", text:'„Izvan okvira"'},
    {value:"10", text:"Psiholozi kao kreativci"},
    {value:"11", text:"Ostale aktivnosti"}],
    layoutStyle:{flex:'1 1 100%'},
    callback: handleFormTypeChange
    }
]

    const [formType, setFormType] = React.useState(makeFormControls(typeSelectorData));

    const extractData = (formControls)=>{
        let data = {}
        for(let c in formControls){
            data[c] = formControls[c].value
        }
        return data
    }

    const sendFormData = () => {
        let activity_data = extractData(formControls)
        activity_data = {...activity_data, ...extractData(formType)}
        let call = fetch(api+"/submit_abstract",{
            method: "POST",
            body:JSON.stringify(
                {activity:activity_data})
            })
            try{
        return call.then(response=>response.json())
            } catch(err){
                console.log(err);
                return {}
            }
    }

    const submitForm = () =>{
        let validity = validateForm(typeSelectorData, formType, setFormType) && validateForm(formData, formControls, setFormControls);
        
        if(validity){
            setModalHidden(false);
            sendFormData().then((response)=>{
                console.log(response)
            })
        }
        console.log(formControls, validity)

    }

    return (
        <>
        
        
        <Form formControls={formType} setFormControls={setFormType} formData={typeSelectorData} />
        
        {
            formData != null?
            <>
            <h2 className="form-subtitle">{formTitle}</h2>
            <div className="flex-row" style={{gap:"1.5rem", flexWrap:"wrap"}} >
            <Form formControls={formControls} formData={formData} setFormControls={setFormControls}/></div></>:<></>
        }
        <div className="tool-tray"><button onClick={submitForm} className='pillbox'>Prijava</button></div>
        <Modal hidden={modalHidden} title="Vaša prijava je zaprimljena" content={<div>Na vašu e-mail adresu primit ćete potvrdu o zaprimanju vaše prijave, a ista će biti poslana na recenziju, o rezultatu koje ćemo vas obavijestiti u najkraćem mogućem roku.</div>} 
            buttons={[<button className="modal-button" onClick={()=>{
                setModalHidden(true);
                window.location.reload();
            }}>U redu</button>]}/>
        
        </>
    )
}