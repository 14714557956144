import * as React from 'react';
import Section from '../section/Section';

import './style.css';
import '../../assets/style.css';

import logo from '../../assets/logo/logo_long.svg'
import { LocationOn, Mail, Phone } from '@mui/icons-material';
import { Link } from 'react-router-dom';
export default function Footer(){
    return(
        <div id="footer" className="background-transparent">
            <Section title={<span className='section-title font-gradient-small title-small'>Kontakt</span>} 
            content={
                <>
            <div className="flex-row flex-centered" style={{width:"100%", fontSize:"1.1rem", gap:"20%"}}>
                <div>
                    <LocationOn/>
                    <p>Hrvatsko psihološko društvo</p>
                    <p>Švarcova 20, 10000 Zagreb</p>
                    <p>OIB: 24373843542</p>
                </div>
                <div style={{textAlign:"left", gap:"0.5rem", justifyContent:"center"}} className="flex-column">
                    <div className="flex-row" style={{alignItems:"center", gap:"2px"}}><Phone/><p>Telefon: 00385(0)912312733</p></div>
                    <div className="flex-row" style={{alignItems:"center", gap:"2px"}}><Mail/><p>Email: konferencija2024@psihologija.hr</p></div>
                </div>
            </div>
            <div style={{fontSize:"1.4rem", padding:"1.2rem"}}>
                <p>Organizator: <Link style={{color:"rgba(83, 147, 251,0.9)"}}  to="https://psihologija.hr">Hrvatsko psihološko društvo</Link></p>
                <p>Suorganizator: <Link style={{color:"rgba(83, 147, 251,0.9)"}}  to="https://psiholozipgz.wixsite.com/dppgz">Društvo psihologa Primorsko-goranske županije</Link> </p>
                <p style={{fontSize:"1.1rem", padding:"1rem", }}>Tehnički organizatori: <Link style={{color:"rgba(83, 147, 251,0.9)"}} to="https://www.vivid-original.com/">VIVID Original d.o.o.</Link> i Ars Intellectus, obrt za računalno programiranje</p>
                <p>2024 © Hrvatsko psihološko društvo. Sva prava pridržana</p>
            </div>
            <div>
                <img className="logo-long" src={logo}/>
            </div>
            </>
            
            }/>
            .</div>
        
    )   
}