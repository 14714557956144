import * as React from 'react';
import Footer from '../../components/footer/Footer';

import '../../assets/style.css';
import { thematix } from '../../data/thematix';
import Section from '../../components/section/Section';
import Navbar from '../../components/nav/Navbar';
export default function Thematic(){
    return (
        <>
        <Navbar/>
        
        <div className="dark-background flex-column" style={{height:"100%", position:"relative"}}>
            <div className="vortex-small"></div>
            <div className="flex-column centered" style={{minHeight:"100vh", alignItems:"center"}}>
                <h1 className="font-gradient-large" style={{paddingTop:"15vh", fontWeight:"400", fontSize:"4rem", marginBottom:"3rem"}}>Tematska područja</h1>
                <div style={{width:"80%", position:"relative"}}>
                {
                    thematix.map(({title, subtitle, content, bullets}, index)=>{
                      return <Section style={{marginBottom:"3rem"}} className={"section-overlay-transparent " + (index%2==0?"section-half-bordered-top":"section-half-bordered-bottom")} 
                      key={index} title={<span><h2  className="font-gradient-small" style={{margin:"0", fontSize:"3rem", fontWeight:"400"}}>{title}</h2></span>} content={
                        <div><h2 style={{fontSize:"2rem",fontWeight:"400", marginTop:"0"}}><span>{subtitle}</span></h2>
                        <div style={{padding:"0 6rem 6rem 6rem"}}>
                            {
                                bullets!=""?
                                <div>
                                {Object.keys(bullets).map((key, index)=>{
                                    return <div style={{fontSize:"1.5rem", textAlign:"left"}} key={index} className="flex-row">
                                        <p style={{padding:"20px", fontSize:"1.7rem"}}>{key})</p><p style={{padding:"20px"}}>{bullets[key]}</p>
                                    </div>
                                })}</div>:<></>
                                
                      }
                            <p style={{fontSize:"1.5rem", whiteSpace:"pre-line", lineHeight:"1.5", textAlign:"left"}}>
                                {content}
                            </p>

                        </div>
                        </div>
                      }/>  
                    })
                }
            
            </div>
            </div>
        <div className="background-overlay">
        <Footer/>
        </div>
        </div>
        </>

    )
}