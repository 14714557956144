import * as React from 'react';

import '../../assets/style.css';
import Footer from '../../components/footer/Footer';
import CheckBox from '../../components/formElements/CheckBox';
import { makeFormControls, validateForm } from '../../components/formElements/util';
import { isEmail, isEmpty } from '../../components/formElements/validators';
import Form from '../../components/formElements/Form';
import Table from '../../components/table/Table';
import Modal from '../../components/modal/Modal';

import axios from 'axios';
import Navbar from '../../components/nav/Navbar';

import { api } from '../../environment';
import PresentationForm from '../../components/forms/PresentationForm';

export default function AbstractApp(){

    return (
        <>
        <Navbar/>

        <div className="flex-column" style={{paddingTop:"18vh",gap:"4rem", width:"100%", justifyContent:"center"}}>
        <div><h1 className="font-gradient-large" style={{fontWeight:"400", fontSize:"3.5rem", textAlign:"center"}}>Prijava sažetaka</h1></div>
            <div style={{width:"50vw", margin:"auto", minHeight:"35vh"}}>
            <PresentationForm />
        </div>
        <div  style={{color:"white"}} className="background-overlay">
                <Footer/>
            </div>
        </div>
        </>
    )
}