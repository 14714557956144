import * as React from 'react';
import PersonImageNormal from '../../components/layouts/PersonImageNormal';
import {useParams } from 'react-router-dom';

import { greetings } from '../../data/greetings';

import '../../assets/style_greeting.css';
import '../../assets/style.css';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/nav/Navbar';

export default function Greetings(){
    const {greeting} = useParams()
    const person = greetings[greeting]
    return (
        <>
        <Navbar/>
    <div className="flex-column centered" style={{minHeight:"60vh", alignItems:"center", paddingTop:"18vh"}}>
        <h1 className="font-gradient-large" style={{fontSize:"2.5rem", fontWeight:"400"}}>{person.section_title}</h1>
    <div id="greeting">
        <PersonImageNormal title={person.title} name={person.name} description={person.description} image={person.image_vert} text={person.text}/>
    </div>
    </div>
    <div className='background-overlay' style={{width:"100%"}}>
    <Footer/>
    </div>
    
    </>)
}