import * as React from 'react';
import Footer from '../../components/footer/Footer';

import '../../assets/style.css';
import '../../assets/style_home.css';
import logo from '../../assets/logo/logo_long.svg'
import Circles from '../../components/aesthetics/Circles';
import Section from '../../components/section/Section';
import { greetings } from '../../data/greetings';
import {lectors} from '../../data/lectors';
import ImageLink from '../../components/layouts/ImageLink';
import Slider from '../../components/slider/Slider';
import { Link } from 'react-router-dom';
import Navbar from '../../components/nav/Navbar';
import { CalendarMonth, LocationOn } from '@mui/icons-material';
import Counter from '../../components/counter/Counter';

export default function Home(){


    return (
        <>
        <Navbar/>
    <div className="dark-background flex-column fullheight">
    <div className="vortex"></div>
    <div id="vortex-middle" className="vortex"></div>
    
    <div>
    <Circles/>
    <div id="speaker-image-wrap">
    
    <div id="speaker-image">
    
            <div id="speaker-cover"> 
           
        <div id="poster-title" className="flex-column-center-2">
            <div style={{textAlign:"center"}}>
                <p id="page-title-text"><span className="font-gradient-large">Suvremeni izazovi</span> mentalnog zdravlja <span className="font-gradient-large inline">u različitim</span> radnim okruženjima</p>
            </div>
            <div>
                <img alt="GKHP31" className="logo-long" src={logo}/>
            </div>
            <p style={{fontSize:"1.5rem"}}>(6. - 9. 11. 2024.)</p>
            <Link to="/prijava-sudjelovanja"><button className="pillbox button">Prijava sudjelovanja</button></Link>
            <div><Counter goalDate={new Date("2024-11-6 09:00")}/></div>

    </div>
    </div>
        </div>
        </div>
    </div>
        <div className='section-padded-large'>
        <Section className="section-padded-large" title={<h1 style={{fontSize:"3.2rem", fontWeight:"400"}}>Dobrodošli na godišnju konferenciju hrvatskih psihologa!</h1>}  
        content={
            <div  style={{fontSize:"1.8rem"}}>
                <p>Pridružite nam se na godišnjem događaju i podijelite svoje uvide na temu</p>
                <p><span className="font-gradient-large" >Suvremeni izazovi mentalnog zdravlja u različitim radnim okruženjima</span></p>
            </div>
        } />
        </div>
        <div className="background-opaque section-padded-large">
        <Section title={<h1 style={{fontSize:"3.2rem", fontWeight:"400"}}>Pozdravne riječi <span className="font-gradient-small">organizatora</span></h1>} 
        content={
            <div className="flex-row flex-centered section-padded-standard" style={{justifyContent:"space-evenly", paddingBottom:"14vh", paddingTop:"2rem", paddingBottom:"4rem"}}>
                {
                Object.keys(greetings).map((key, index)=>{
                    let person = greetings[key];
                    return <div key={key}  style={{width:"22%"}}><ImageLink title={person.title} button={true} name={person.name} description={person.description} link={person.link}  image={person.image}/></div>
                })
        }
            </div>
        } />
        </div>
        <div className="background-transparent section-padded-large">
        <Section title={<h1 style={{fontSize:"3.2rem", fontWeight:"400"}}>Pozvani predavači</h1>} 
        content={<>
            <div className="flex-row flex-centered" style={{justifyContent:"space-evenly", flexWrap:"wrap", paddingTop:"2rem", paddingBottom:"4rem"}}>
                {
                    Object.keys(lectors).slice(0,3).map((key, index)=>{
                        let lector = lectors[key]
                return <div key={key}  style={{width:"20%"}}><ImageLink link={lector.link} button={true} title={lector.title}  name={lector.name} description={lector.description} image={lector.image}></ImageLink></div>
                })
            }
            </div>
            <div className="flex-row flex-centered" style={{justifyContent:"space-evenly", flexWrap:"wrap", paddingBottom:"14vh", paddingBottom:"4rem"}}>
                {
                Object.keys(lectors).slice(3).map((key, index)=>{
                    let lector = lectors[key]
                return <div key={key}  style={{width:"20%"}}><ImageLink link={lector.link} button={true} title={lector.title}  name={lector.name} description={lector.description} image={lector.image}></ImageLink></div>
            })
            }
            </div>
            </>
        } />
        </div>
        <div className="section-background">
            <div className='section-overlay section-padded-large'>
        <Section contentStyle={{paddingTop:"3vh", paddingBottom:"15vh", display:"flex", flexDirection:"column", gap:"1.5rem"}} 
        content={
            <>
            <div  style={{fontSize:"1.8rem"}}>
                <h1 style={{fontWeight:"400"}}><span className="font-gradient-small">Budite</span> dio konferencije</h1>
            </div>
            <div className='flex-column' style={{gap:"1rem"}}>
                <div style={{justifyContent:"center", alignItems:"center", gap:"4.6rem"}} className="flex-row"><p style={{fontSize:"1.7rem"}}><CalendarMonth/> 6.- 9. studenog 2024. </p><Link to="/prijava-sudjelovanja"><button className="pillbox button">Prijava sudjelovanja</button></Link></div>
                <div style={{justifyContent:"center", alignItems:"center", gap:"2rem"}} className="flex-row"><p style={{fontSize:"1.7rem"}}><LocationOn/> Hotel Ambasador, Opatija </p><Link to="/tematska-podrucja"><button className="pillbox-white button">Tematska područja</button></Link></div>
            </div>
            </>
        } />
        </div>
        </div>
        <div className="background-opaque section-padded-large">
        <Section title={<h1 style={{fontSize:"3.2rem", fontWeight:"400"}}>Važni datumi</h1>} 
        content={
            <div className="flex-row flex-centered section-padded-standard" style={{justifyContent:"space-evenly", paddingBottom:"14vh", paddingTop:"2rem", paddingBottom:"4rem"}}>
                <div className="flex-column" style={{width:"50%"}}>
                    <div className="flex-row" style={{justifyContent:"space-between", alignItems:"center"}}>
                        <div className="pillbox-date">31.7.2024</div><div style={{fontSize:"1.5rem"}}>Rok za uplatu rane kotizacije</div>
                    </div>
                    <div className="flex-row" style={{justifyContent:"space-between", alignItems:"center"}}>
                        <div className="pillbox-date">20.09.2024</div><div style={{fontSize:"1.5rem"}}>Krajnji rok za prijavu sažetka</div>
                    </div>
                    <div className="flex-row" style={{justifyContent:"space-between", alignItems:"center"}}>
                        <div className="pillbox-date">13.10.2024</div ><div style={{fontSize:"1.5rem"}}>Rok za uplatu srednje kotizacije</div>
                    </div>
                    <div className="flex-row" style={{justifyContent:"space-between", alignItems:"center"}}>
                        <div className="pillbox-date">5.11.2024</div><div style={{fontSize:"1.5rem"}}>Rok za uplatu kasne kotizacije</div>
                    </div>
                </div>
            </div>
        } />
        </div>
        <div>
        <Footer/>
        </div>
    </div>
    </>
    )
}