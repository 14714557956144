import * as React from 'react';
import Footer from '../../components/footer/Footer';

import '../../assets/style.css';
import '../../assets/style_org.css'
import Section from '../../components/section/Section';
import Navbar from '../../components/nav/Navbar';

export default function NoMobile(){

    return (
        <>
        <div className='dark-background' style={{position:"relative"}} >
            <div className="vortex-small"></div>
            <div className="section-padded-large" style={{minHeight:"60vh"}}>
                <Section  title={<h1 style={{fontSize:"3rem", fontWeight:"400"}}>Mobilna verzija stranice <span className="font-gradient-large">je u izradi</span>.</h1>}
                content={<p>Molimo, posjetite nas putem osobnog računala.</p>}/>
            
            </div>

    </div>
    </>
    
)
}