import Form from "./Form";

export const makeFormControls =(formData, formControls=null)=>{
    if(formControls==null){
        formControls = {}
    }

    let defaultValue="";

    for(let field of formData){
        if(field.type==="subtitle"){
            continue;
        }
        if(field.defaultValue!=undefined){defaultValue=field.defaultValue}
        formControls[field.name] = {value:field.type=="tabular"?[]:"", validity:{valid:null, necessary:field.necessary, formNecessary:field.formNecessary!=null?field.formNecessary:true, warning:""}}
    }

    return formControls;
}

export const extractFormControlValues = (formControls) =>{
    let values={}
    for(let formControl in formControls){
        values[formControl] = formControls[formControl].value
    }
    return values;
}

export const validateForm=(formData, formControls, setFormControls)=>{
    if(formData===null || formData===undefined){
        return false;
    }

        setFormControls((prevState)=>{

            for(let field of formData){
                if(field.type==="subtitle"){
                    continue
                }

                let validity = prevState[field.name].validity;

                if(validity.formNecessary!=false && validity.necessary){

                for(let i= 0; i<field.validators.length; i++){
                    validity = field.validators[i](prevState[field.name].value)
                    }
                }
                prevState[field.name].validity = validity
            }
            return {
                ...prevState
            }

        })
        for(let field of formData){
            if(field.type==="subtitle"){
                continue
            }
            if(formControls[field.name].validity.formNecessary==true && 
                formControls[field.name].validity.necessary && 
                formControls[field.name].validity.valid!=true){
                return false
            }
        }
    
        return true;
}

export const randomId = () => {
    return (Math.random() + 1).toString(36).substring(7);
}

export const setFieldValueOld = (fieldName, value, formControls) => {
    let formControlsCopy = structuredClone(formControls);
    formControlsCopy[fieldName].value = value;
    return formControlsCopy;
}

export const setFieldValidity = (fieldName, validity, formControls) =>{
    formControls[fieldName].validity = validity;
}

export const setFieldValue = (fieldName, value, formControls) => {
    formControls[fieldName].value = value;
}

export const makeFieldChangeHandleOld = (field, formControls, setFormControls, validateField) => {
        return (e) => {
            let formControlsCopy = setFieldValue(field.name, e.target.value, formControls);
            if(validateField!=undefined){
                formControlsCopy = validateField(formControlsCopy);
            }
            setFormControls(formControlsCopy);
            if(field.callback!=undefined){
                field.callback(e);
            }
        }
    }

    export const makeValidateField = (field, setValid) =>{
        return (newField) => {
            for(let validator of field.validators){
                let validity = validator(newField.value);
                
                if(!validity.valid){
                    setValid(validity);
                    return validity
                }
            }
            return {valid: true, necessary: field.necessary, formNecessary: field.formNecessary, warning:""}
        }
    }

    export const makeFieldChangeHandle = (field, setFormControls, validateField) => {
        return (e) => {
            setFormControls((prevState)=>{
                let newField = {value:e.target.value, validity:prevState[field.name].validity}
            if(field.callback!=undefined){
                field.callback(e);
            }
            if(field.necessary){
                newField.validity = validateField(newField);
            }
            let newState = {}
            newState[field.name] = newField;
            return {
                ...prevState,
                ...newState
            }
            })
        }
    }



export const makeValidateFieldOld = (field, setValid) =>{
    return (formControls) => {
        let formControlsCopy = structuredClone(formControls);
        for(let validator of field.validators){
            let validity = validator(formControls[field.name].value);
            formControlsCopy = setFieldValidity(field.name, validity, formControls);
            setValid(validity);
            if(!validity.valid){
                return formControlsCopy
            }
        }
        return formControlsCopy;
    }
}

export const setSelectDefaultValue = (field, formControls, setFormControls) => {
    let formControlsCopy = structuredClone(formControls);
    formControlsCopy[field.name].defaultValue= field.defaultValue;
    setFormControls(formControlsCopy);
}

export function FormDataBuilder(formControls, setFormControls) {
    this.fields= []
    this.get= ()=>{return this.fields}
    this.addField= (newField) =>{this.fields.push(newField);return this}
    this.addTextField= (name, label, necessary, formNecessary=true, validators=[], layoutStyle={}, callback=null, className=undefined) => {
        this.addField({name:name, type:"text", label:label, necessary:necessary, formNecessary:formNecessary, validators:validators, layoutStyle:layoutStyle, callback: callback, className:className})
        return this
    }
    
    this.addPasswordField= (name, label, necessary, formNecessary=true, validators=[], layoutStyle={}, callback=null, className=undefined)=>{
        this.addField({name:name, type:"password", label:label, necessary:necessary, formNecessary:formNecessary, validators:validators, layoutStyle:layoutStyle, callback: callback, className:className})
    }

    this.addSelectField= (name, label, necessary, options=[],formNecessary=true, validators=[], layoutStyle={}, callback=null, className=undefined)=>{
        this.addField({name:name, type:"select", label:label, necessary:necessary, formNecessary:formNecessary, validators:validators, layoutStyle:layoutStyle, callback: callback, options:options, className:className})
        return this;
    }

    this.addTextArea= (name, label, necessary, validators, layoutStyle, callback=null) => {
        this.addField({name:name, type:"textarea", label:label, necessary:necessary, validators: validators, layoutStyle:layoutStyle, callback:callback})
        return this;
    }

    this.addTabular= (name, label, necessary, header, validators=[], layoutStyle={}, cols=undefined, mode="add") =>{
        this.addField({name:name, type:"tabular", header:header, label:label, necessary:necessary, validators:validators, layoutStyle:layoutStyle, cols:cols, mode:mode})
        return this;
    }

    this.addSubtitle=(text, layoutStyle={flex:"1 1 100%"}) => {
        this.addField({text:text, type:"subtitle", layoutStyle:layoutStyle})
        return this
    }

    this.Form = () =>{
        return (
         <Form formData={this.fields} formControls={this.formControls} setFormControls={this.setFormControls}></Form>   
        )
    }
}