import * as React from "react";
import ReactDOM from 'react-dom/client';
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  Navigate
} from "react-router-dom";

import Home from "./controllers/Public/Home";
import Lectors from "./controllers/Public/Lectors";
import ParticipationForms from "./controllers/Public/ParticipationForms";
import Thematic from "./controllers/Public/Thematic";
import ParticipationApplication from "./controllers/Public/ParticipationApplication";
import Greetings from "./controllers/Public/Greetings";
import NotFound from "./controllers/Public/NotFound";
import CRS from "./controllers/Crs/CRS";
import PublicLayout from "./controllers/Public/PublicLayout";
import Organization from "./controllers/Public/Organization";
import Test from "./controllers/Test";
import Login from "./controllers/Public/Login";
import Dashboard from "./controllers/Crs/Dashboard";
import AuthProvider from "./components/AuthProvider";
import PrivateRoute from "./components/PrivateRoute";
import ApplicationsView from "./controllers/Crs/ApplicationsView";
import AbstractApp from "./controllers/Public/AbstractApp";
import ApplicationView from "./controllers/Crs/ApplicationView";
import LectorsSlide from "./controllers/Public/LectorsSlide";
import AbstractsView from "./controllers/Crs/AbstractsView";
import AbstractView from "./controllers/Crs/AbstractView";


const root = ReactDOM.createRoot(document.getElementById("root"));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

root.render(
    <BrowserRouter>
    <ScrollToTop/>
    <AuthProvider>
    <Routes>
    
      <Route path="/" element={<PublicLayout/>}>
        <Route index element={<Home/>}/>
        <Route path="organizacija" element={<Organization/>}/>
        <Route path="oblici-sudjelovanja" element={<ParticipationForms/>}/>
        <Route path="pozvani-predavaci" element={<LectorsSlide/>}/>
        <Route path="pozvani-predavaci/:lector" element={<Lectors/>}/>
        <Route path="tematska-podrucja" element={<Thematic/>}/>
        <Route path="prijava-sudjelovanja" element={<ParticipationApplication/>}/>
        <Route path="pozdravna-rijec/:greeting" element={<Greetings/>}/>
        <Route path="prijava-sazetaka" element={<AbstractApp/>}/>
        <Route path="testis" element={<Test/>}/>
      </Route>
      
      <Route path="/login" element={<Login/>}/>
      <Route path="/crs" element={<PrivateRoute><CRS/></PrivateRoute>}>
        <Route index element={<Dashboard/>}/>
        <Route path="pregled-prijava-sudjelovanja" element={<ApplicationsView/>}/>
        <Route path="pregled-prijava-sudjelovanja/:id" element={<ApplicationView/>}/>
        <Route path="pregled-sazetaka" element={<AbstractsView/>}/>
        <Route path="pregled-sazetaka/:id" element={<AbstractView/>}/>
      </Route>
      <Route path="/*" element={<NotFound/>}/>
    </Routes>
    </AuthProvider>
    
    </BrowserRouter>
  )
